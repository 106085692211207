import { Form, useLocation } from 'react-router'
import { Icon } from '~/components/icon/icon'
import {
	FACEBOOK_PROVIDER_NAME,
	GOOGLE_PROVIDER_NAME,
	providerNames,
	type ProviderName,
} from '~/utils/auth/providers/provider.helper'
import { Button } from '../button/button'

export const providerLabels: Record<ProviderName, string> = {
	[GOOGLE_PROVIDER_NAME]: 'Google',
	[FACEBOOK_PROVIDER_NAME]: 'Facebook',
} as const

export const providerIcons: Record<ProviderName, React.ReactNode> = {
	[GOOGLE_PROVIDER_NAME]: <Icon name="google" size="md" />,
	[FACEBOOK_PROVIDER_NAME]: <Icon name="facebook" size="md" />,
} as const

export function ProviderConnectionForm({
	redirectTo,
}: {
	redirectTo?: string
}) {
	const location = useLocation()
	const currentPath = `${location.pathname}${location.search}`

	return (
		<div className="mt-4 flex items-center justify-center gap-2 border-t border-border pt-4">
			{providerNames.map(providerName => {
				const label = providerLabels[providerName]
				const formAction = `/auth/${providerName}`

				return (
					<Form
						action={formAction}
						method="POST"
						target="_blank"
						key={`oauth${label}`}
					>
						<input
							type="hidden"
							name="redirectTo"
							value={redirectTo ?? currentPath}
						/>
						<Button
							type="submit"
							variant="outline"
							className="rounded px-4 py-2 font-semibold text-black dark:text-white"
						>
							{providerIcons[providerName]}
							<span className="ml-2">{label}</span>
						</Button>
					</Form>
				)
			})}
		</div>
	)
}
